import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from './colors';

export const spacingUnit = 0.25;

export const spacings = {
  unit: `${spacingUnit}rem`,
  halfUnit: `${spacingUnit * 0.5}rem`,
  doubleUnit: `${spacingUnit * 2}rem`,
  header: `${spacingUnit * 4}rem`,
  section: `${spacingUnit * 10}rem`,
};

export const resetElementSpacing = css`
  margin: 0;
`;

export const commonElementSpacing = css`
  margin: 0 0 ${spacings.section} 0;
  padding: 0;
`;

export const sizes = {
  phoneS: 320,
  phoneXL: 576,
  tablet: 768,
  desktop: 992,
  desktopXL: 1280,
};

export const device = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = `@media (min-width: ${sizes[label]}px)`;

  return acc;
}, {});

export const metrics = {
  iconSize: '12px',
};

export const layoutContainer = css`
  padding: ${spacings.unit} 32px;

  ${device.desktop} {
    margin: 0 auto;
    max-width: 1000px;
  }
`;

export const HR = styled('hr')`
  border: 0.5px solid ${colors.darkGrey};
  margin: ${spacings.section} 0;
`;
