export const colors = {
  primary: '#ECC8CA',
  primaryDark: '#621734',
  secondary: '#A12657',
  tertiary: '',
  white: '#FFF',
  offWhite: '#FDFDFD',
  black: '#000',
  offBlack: '#111',
  grey: '#828282',
  lightGrey: '#F6F6FE',
  darkGrey: '#E8E8E8',
  legibleGrey: '#575757',
};

export const colorFunctions = {
  border: colors.darkGrey,
  textPrimary: colors.offBlack,
  textLight: colors.legibleGrey,
  textLink: colors.secondary,
  textLinkHover: colors.offBlack,
  error: '',
  deactivated: '',
};
