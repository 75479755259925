import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const YouTubeEmbedContainer = styled('div')`
  margin: 0 auto;
  ${(props) => !!props.width && `width: ${props.width};`}
`;

const YouTubeEmbedWrapper = styled('div')`
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
`;

const YouTubeIFrame = styled('iframe')`
  border: 0;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: absolute;
`;

export const YouTubeEmbed = ({ width, videoId }) => {
  return (
    <YouTubeEmbedContainer width={width}>
      <YouTubeEmbedWrapper>
        <YouTubeIFrame
          allowfullscreen
          src={`https://www.youtube.com/embed/${videoId}`}
        />
      </YouTubeEmbedWrapper>
    </YouTubeEmbedContainer>
  );
};

YouTubeEmbed.propTypes = {
  width: PropTypes.number,
  videoId: PropTypes.string.isRequired,
};

export default YouTubeEmbed;
