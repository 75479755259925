import React from 'react';
import styled from '@emotion/styled';
import { colorFunctions } from '../styles/colors';
import { layoutContainer } from '../styles/layouts';
import A from '../components/link';

const FooterContainer = styled('footer')`
  border-top: 1px solid ${colorFunctions.border};
  padding: 2rem 0;
`;

const FooterContentContainer = styled('div')`
  ${layoutContainer}
  display: flex;
  justify-content: space-between;
`;

const FooterContentColumn = styled('div')`
  display: flex;
  flex-direction: column;

  a {
    margin-bottom: 1rem;
  }
`;

const Footer = () => {
    return (
        <FooterContainer>
            <FooterContentContainer>
                <FooterContentColumn>
                    <A href="https://www.ericaashley.com">Main</A>
                    <A href="https://blog.ericaashley.com">Blog</A>
                    <A href="https://recipes.ericaashley.com">Recipes</A>
                    <A href="https://github.com/ericaashley">GitHub</A>
                    <A href="https://www.linkedin.com/in/ericakangas">LinkedIn</A>
                    <A href="https://www.twitter.com/__ericaashley__">Twitter</A>
                </FooterContentColumn>

                <FooterContentColumn>
                    {`© ${new Date().getFullYear()} Erica Kangas`}
                </FooterContentColumn>
            </FooterContentContainer>
        </FooterContainer>
    );
};

export default Footer;
