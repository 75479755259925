import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorFunctions } from '../styles/colors';

export const linkStyles = css`
  background-color: transparent;
  color: ${colorFunctions.textLink};
  text-decoration: none;
  -webkit-text-decoration-skip: objects;

  &:active,
  &:hover {
    color: ${colorFunctions.textLinkHover};
    outline-width: 0;
    text-decoration: underline;
  }
`;

export const A = styled('a')`
  ${linkStyles}
`;

export default A;
