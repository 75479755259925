/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/core';
import styled from '@emotion/styled';
import globalStyles from '../styles/global';
import { layoutContainer } from '../styles/layouts';
import GoogleTagManager from '../components/googleTagManager';
import Header from '../components/header';
import Footer from '../components/footer';

const PageContentContainer = styled('div')`
  ${layoutContainer}
`;

const Page = ({ children }) => {
    return (
        <>
            <GoogleTagManager />
            <Global styles={globalStyles} />
            <Header />
            <PageContentContainer>
                <main>{children}</main>
            </PageContentContainer>
            <Footer />
        </>
    );
};

Page.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Page;
