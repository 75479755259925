import { css } from '@emotion/core';
import { colorFunctions } from './colors';

const MIN_VIEWPORT_SIZE = 20; // 320px
const MAX_VIEWPORT_SIZE = 90; // 1440px

export const responsiveFont = (minSize, maxSize) => `
  font-size: calc(${minSize}rem + (${maxSize} - ${minSize}) * ((100vw - ${MIN_VIEWPORT_SIZE}rem) /(${MAX_VIEWPORT_SIZE} - ${MIN_VIEWPORT_SIZE})));
`;

export const typefaces = css`
  @import url('https://fonts.googleapis.com/css?family=Josefin+Slab');

  @import url('https://fonts.googleapis.com/css?family=Open+Sans');
`;

const DEFAULT_LINE_HEIGHT = 1.5;
export const DEFAULT_LETTER_SPACING = '0.025em';
export const DEFUALT_DISPLAY_LETTER_SPACING = '0.03em';

export const fonts = {
  base: {
    fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontKerning: 'normal',
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: DEFAULT_LETTER_SPACING,
    lineHeight: DEFAULT_LINE_HEIGHT,
    textRendering: 'optimizeLegibility',
    wordWrap: 'break-word',
    MozFontFeatureSettings: "'kern', 'liga', 'clig', 'calt'",
    msFontFeatureSettings: "'kern', 'liga', 'clig', 'calt'",
    WebkitFontFeatureSettings: "'kern', 'liga', 'clig', 'calt'",
    fontFeatureSettings: "'kern', 'liga', 'clig', 'calt'",
  },
  title: {
    fontFamily: '"Josefin Slab", Georgia, Garamond, serif',
  },
  display: {
    fontFamily: '"Josefin Slab", Georgia, Garamond, serif',
    letterSpacing: DEFUALT_DISPLAY_LETTER_SPACING,
  },
  error: {
    fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontWeight: 400,
    fontStyle: 'italic',
    color: colorFunctions.error,
  },
  thin: {
    fontFamily: '"Josefin Slab", Georgia, Garamond, serif',
    fontWeight: 100,
    fontStyle: 'normal',
    lineHeight: DEFAULT_LINE_HEIGHT,
  },
  thinItalic: {
    fontFamily: '"Josefin Slab", Georgia, Garamond, serif',
    fontWeight: 100,
    fontStyle: 'italic',
    lineHeight: DEFAULT_LINE_HEIGHT,
  },
  light: {
    fontWeight: 300,
    fontStyle: 'normal',
  },
  lightItalic: {
    fontWeight: 300,
    fontStyle: 'italic',
  },
  italic: {
    fontWeight: 400,
    fontStyle: 'italic',
  },
  semiBold: {
    fontWeight: 600,
    fontStyle: 'normal',
  },
  semiBoldItalic: {
    fontWeight: 600,
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 700,
    fontStyle: 'normal',
  },
  boldItalic: {
    fontWeight: 700,
    fontStyle: 'italic',
  },
  extraBold: {
    fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontWeight: 800,
    fontStyle: 'normal',
    lineHeight: DEFAULT_LINE_HEIGHT,
  },
  extraBoldItalic: {
    fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontWeight: 800,
    fontStyle: 'italic',
    lineHeight: DEFAULT_LINE_HEIGHT,
  },
};
